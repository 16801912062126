var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isRouterAlive)?_c('gtLayout',{attrs:{"site-info":_vm.siteInfo,"nav-header-list":_vm.navHeaderList,"nav-footer-list":_vm.navFooterList,"is-mobile":_vm.mobile,"is-guest":_vm.isGuest,"is-login":_vm.isLogin,"cart-num":_vm.miniCartNum,"account-info":_vm.accountInfo,"reload":_vm.reload,"my-nav-list":_vm.myNavList,"other-menus":[],"switch-lang":[],"cur-lang-label":"","is-webp":_vm.isWebp,"oss-resize":_vm.ossResize,"ad-content":_vm.adContent},scopedSlots:_vm._u([{key:"footer",fn:function(ref){
var data = ref.data;
return _c('div',{},[_c('Footer',{attrs:{"nav-footer-list":data,"site-info":_vm.siteInfo},on:{"custom-event":function($event){return _vm.customServerValid(true)}}})],1)}}],null,false,1182453567)},[(!_vm.isMobile && !['wxpay', 'paySuccess', 'payAgain'].includes(_vm.$route.name))?[(!_vm.isMobile)?_c('div',{staticClass:"sp-header-top",class:{
          'hidden-top': _vm.$route.path === '/' ? !_vm.isTop : false
        },attrs:{"slot":"header-top"},slot:"header-top"},[_c('div',{staticClass:"sp-header-top__wrapper"},[_c('marquee',{staticClass:"nav-marquee",attrs:{"scrollamount":"4","scrolldelay":"100"}},[_c('div',{staticClass:"cms-html-edit",staticStyle:{"font-size":"12px"}},[_c('Advertisement',{attrs:{"slot":"header-bottom","data":_vm.adContent.TopAnnouncementBar},slot:"header-bottom"}),_vm._v(" "),_c('input',{staticStyle:{"display":"none"},attrs:{"name":"old-userName","type":"text"}}),_vm._v(" "),_c('input',{staticStyle:{"display":"none"},attrs:{"name":"old-pwd","type":"password"}})],1)]),_vm._v(" "),_c('div',{staticClass:"sp-header-top__right"},[_c('div',{staticClass:"sp-header-top__right-links"},[_c('div',{staticClass:"sp-header-top__right-links-item"},[_c('UaHeaderMy')],1)]),_vm._v(" "),_c('div',{staticClass:"sp-header-top__border"}),_vm._v(" "),_c('span',{directives:[{name:"click-track",rawName:"v-click-track:buttonClick",value:({
                module_name: '顶部-在线客服',
                button_name: '在线客服'
              }),expression:"{\n                module_name: '顶部-在线客服',\n                button_name: '在线客服'\n              }",arg:"buttonClick"}],staticClass:"customer-service-btn",staticStyle:{"cursor":"pointer","height":"12px","line-height":"12px"},on:{"click":function($event){return _vm.customServerValid(true)}}},[_vm._v("\n              在线客服\n            ")]),_vm._v(" "),_c('div',{staticClass:"sp-header-top__border"}),_vm._v(" "),_c('span',{staticStyle:{"cursor":"pointer","height":"12px","line-height":"12px"},on:{"click":_vm.handleBrand}},[_vm._v("\n              品牌故事\n            ")])])],1)]):_vm._e(),_vm._v(" "),(!_vm.isMobile)?_c('router-link',{staticClass:"nav-header-logo",attrs:{"slot":"header-left","to":{ name: 'home' }},slot:"header-left"},[_c('img',{staticStyle:{"width":"64px","height":"64px"},attrs:{"alt":"logo","src":require("@/assets/image/UA_logo.svg")},on:{"click":function($event){return _vm.$refs.$search.handleShowInput(false)}}})]):_vm._e(),_vm._v(" "),(!_vm.isMobile)?_c('div',{staticStyle:{"margin-right":"auto","overflow":"hidden"},attrs:{"slot":"header-center"},slot:"header-center"},[_c('UaNav',{staticClass:"ua-nav",attrs:{"data":_vm.navHeaderList},on:{"openNav":function($event){return _vm.$refs.$search.handleShowInput(false)}}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"header-right",attrs:{"slot":"header-right"},slot:"header-right"},[_c('div',{staticClass:"header-wish-cart"},[(!_vm.isMobile)?_c('searchHeader',{ref:"$search",staticClass:"is-pc gt-header-right__search"}):_vm._e(),_vm._v(" "),_c('div',[_c('img',{directives:[{name:"click-track",rawName:"v-click-track:buttonClick",value:({
                module_name: '顶部-我的收藏',
                button_name: '我的收藏'
              }),expression:"{\n                module_name: '顶部-我的收藏',\n                button_name: '我的收藏'\n              }",arg:"buttonClick"}],staticClass:"iconfont icon-heart icon-a-16-1px-like1",staticStyle:{"filter":"brightness(0) invert(1) drop-shadow(0 0 0 #fff)","cursor":"pointer"},attrs:{"src":require("@/assets/ua-icon24/icon-heart.svg"),"alt":"icon-search"},on:{"click":_vm.handleClickWish}})]),_vm._v(" "),_c('div',{directives:[{name:"click-track",rawName:"v-click-track:buttonClick",value:({
              module_name: '顶部-查看购物袋',
              button_name: '查看购物袋'
            }),expression:"{\n              module_name: '顶部-查看购物袋',\n              button_name: '查看购物袋'\n            }",arg:"buttonClick"}],staticClass:"icon-shopping",on:{"click":function($event){return _vm.$router.push({ name: 'shopCart' })}}},[_c('img',{staticStyle:{"filter":"brightness(0) invert(1) drop-shadow(0 0 0 #fff)","width":"16px","height":"16px"},attrs:{"src":require("@/assets/ua-icon24/icon-shopping-bag.svg"),"alt":"icon-search"},on:{"mouseover":_vm.showMiniCart}}),_vm._v(" "),(_vm.miniCartNum > 0)?_c('span',{staticClass:"cart-num"},[_vm._v("\n              "+_vm._s(_vm.miniCartNum > 99 ? '99+' : _vm.miniCartNum)+"\n            ")]):_vm._e()])],1)]),_vm._v(" "),_c('div',{attrs:{"slot":"header-nav"},slot:"header-nav"},[_c('div')])]:(_vm.isMobile && !['wxpay', 'paySuccess', 'payAgain'].includes(_vm.$route.name))?[_c('mobileHeader',{attrs:{"slot":"header","mini-cart-num":_vm.miniCartNum,"nav-header-list":_vm.navHeaderList,"nav-brand-series-list":_vm.navBrandSeriesList,"marquee-data":_vm.adContent.TopAnnouncementBar,"my-nav-list":_vm.myNavList},slot:"header"})]:[_c('div',{attrs:{"slot":"header"},slot:"header"})],_vm._v(" "),_vm._v(" "),_c('template',{slot:"page-top-ad"},[(_vm.adPageTop.length && _vm.$route.name !== 'pdp' && _vm.$route.name !== 'BlindPackagePDP')?_c('topAd',{key:_vm.$route.path,staticClass:"count-topAd-class",attrs:{"data":_vm.adPageTop}}):_c('section')],1)],2):_vm._e(),_vm._v(" "),_c('no-ssr',[(!_vm.isMobile)?_c('ShippingBagDrawer',{ref:"miniShopCart"}):_vm._e()],1),_vm._v(" "),_c('no-ssr',[_c('Login',{ref:"drawerLogin",attrs:{"from-button":_vm.showDrawerLoginButton,"visible":_vm.visible},on:{"update:visible":function($event){_vm.visible=$event}}})],1),_vm._v(" "),_c('no-ssr',[_c('brandStory',{ref:"brandStoryRef"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from 'vue';
import VueLazyload from 'vue-lazyload';
Vue.prototype.$eventBus = new Vue();

const commonConfig = {
  install(Vue) {
    Vue.prototype.$gtm = {
      push() {}
    };
    Vue.prototype.commonConfig = {
      FACEBOOK_PAGE_ID: '**',
      FACEBOOK_APP_ID: '1231518084361070',
      GOOGLE_CLIENT_ID: '714014579782-d3ig7mjalceojkjt737vrv3e885krfun.apps.googleusercontent.com',
      // size 属性匹配
      ATTR_SIZE_CODE: /^size(\W|\w){0,100}$/i,
      //  TODO: 项目主要字段匹配规则 (?![\s])(?!.*\s$)不以空格开头或者结尾
      titlePattern: /^[\s\·_-（）()\dA-Za-z\u4E00-\u9FA5]{1,100}$/,
      //  TODO: 项目主要字段匹配规则 (?![\s])(?!.*\s$)不以空格开头或者结尾
      namePattern: /^[\s\.·\dA-Za-z\u4E00-\u9FA5]{1,30}$/,
      emailPattern: /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-_.])+[A-Za-z\d]{2,3}$/,
      passwordPattern:
       /^(?:(?=.*[A-Za-z])(?=.*\d)|(?=.*[A-Za-z])(?=.*[!@#$%^&*()])|(?=.*\d)(?=.*[!@#$%^&*()])).{8,16}$/g,
      phonePattern: /^1[3-9]\d{9}$/,
      rules: {
        email: [
          {
            pattern: /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-_.])+[A-Za-z\d]{2,3}$/
          }
        ],
        password: [
          {
            required: true,
            message: 'Required',
            trigger: 'blur'
          },
          {
            pattern:
             /^(?:(?=.*[A-Za-z])(?=.*\d)|(?=.*[A-Za-z])(?=.*[!@#$%^&*()])|(?=.*\d)(?=.*[!@#$%^&*()])).{8,16}$/g,
            message: 'The value must contain alphanumeric special characters And at least six',
            trigger: 'blur'
          }
        ],
        phone: [
          {
            required: true,
            pattern: /^1[3-9]\d{9}$/,
            message: 'Please input the correct phone number',
            trigger: ['submit', 'change', 'blur']
          }
        ]
      }
    };
  }
};
Vue.use(commonConfig);

Vue.use(VueLazyload);
